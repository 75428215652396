<template>
  <div class="container">
    <div class="mt-5 mb-4">
      <router-link to="/marketplace" class="text-secondary">
        <fa :icon="['fa', 'chevron-left']" />
        <span class="ml-2"> Back</span>
      </router-link>
    </div>
    <section class="card nft-page" style="border-radius:32px">
      <header>
        <h3 class="text-white">{{ title }}</h3>
      </header>
      <section>img</section>
      <div>info</div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'NftDetail',
  data() {
    return {
      title: 'Kreepy Club #1479',
    }
  },
}
</script>

<style lang="scss" scoped>
.nft-page {
  padding: 40px;

  grid-template-areas:
    'A B'
    'C B';

  grid-template-columns: minmax(0, 480px) minmax(0, 1fr);
  grid-template-rows: min-content 1fr;

  & > header {
    grid-area: A;
  }

  & > section {
    grid-area: B;
  }

  & > div {
    grid-area: C;
  }
}
</style>
